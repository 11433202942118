/*GNT Screening pass events from body*/
document.addEventListener('click', (e) => {
    if (e.target === document.body && (document.body.classList.contains("screening_wallpaper") || document.body.classList.contains("adslot--tapeta"))) {
        const gntScreening = document.querySelector(".gnt-screening.gnt-screening--clickable");
        if (gntScreening)
            //@ts-ignore
            gntScreening.dispatchEvent(new e.constructor(e.type, e))
    }
});
const GNT_IFRAME_MOUSE_PASS_EVENT = (e: any) => {
    if (e.target === document.body && (document.body.classList.contains("screening_wallpaper") || document.body.classList.contains("adslot--tapeta"))) {
        const gntScreeningIframe = document.querySelector(".gnt-screening.gnt-screening--clickable iframe");
        if (gntScreeningIframe) {
            //@ts-ignore
            gntScreeningIframe?.contentWindow?.postMessage(`mouseEvent-${e.type}`);
        }
    }
};
const GNT_IFRAME_MOUSE_EVENTS: (keyof DocumentEventMap)[] = ['mouseover', 'click'];
GNT_IFRAME_MOUSE_EVENTS.forEach((mouseEvent) => document.addEventListener(mouseEvent, GNT_IFRAME_MOUSE_PASS_EVENT));

export { };