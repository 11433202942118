// import Cookies from "cookies-js";

const IS_PRIVILEGED_USER = false; // !!(Cookies.get("IS_PRIVILEGED_USER"));

/* timeout używany do refreshowania strony, jeśli tab nie jest sfocusowany*/
export const APP_REFRESH_TIME = 120000;

/* regex do łapania parametrów zdjęć z cdna, <szerokość>,<wysokość>,<nie zachowuj aspect ratio>,<czarno-biały> */
export const IMG_SRC_PARAMS_REGEX = /,\d{1,3},\d{1,3},[0-1],[0-1]/;
export const MEDIA_SRC_PARAMS_REGEX = /,\%WIDTH\%,\%HEIGHT\%,\%CROP_FLAG\%,\%GRAYSCALE_FLAG\%\.[\w+]*/;

export const GOOGLE_MAPS_REGEX = /^https?:\/\/(?:www\.|maps\.)?google\.[a-z]+\/maps\/embed\?pb=(.*)/
export const YOUTUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/
export const FB_COMMENT_REGEX = /^https?:\/\/www\.facebook\.com\/.*\/posts\/\d{1,}\?comment_id=\d{1,}$/
export const FB_POST_REGEX = /^https?:\/\/www\.facebook\.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^/]+\/(activity|posts))[/?].*$/
export const INSTAGRAM_POST_REGEX = /^https?:\/\/www\.instagram\.com\/(?:p|reel)\/([^/?&]+)\/?/
export const TWITTER_REGEX = /^https?:\/\/(?:twitter|x)\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?$/
export const TIKTOK_REGEX = /^https?:\/\/(?:www.)?tiktok\.com\/(@.{1,24})?\/video\/(\d+)?(?:\?.*)?/
export const INFOGRAM_REGEX = /^https?:\/\/infogram.com\/?/

/** feature flags */
export const IS_BILLBOARD_1_POD_LEADEM = !!window.APP_SETTINGS?.feature_flags?.billboard1PodLeadem
export const IS_FEATURE_mundial2022 = !!window.APP_SETTINGS?.feature_flags?.mundial2022;
export const IS_FEATURE_adunityPartner = !!window.APP_SETTINGS?.feature_flags?.adunityPartner
export const IS_FEATURE_TIP = !!window.APP_SETTINGS?.feature_flags?.flagTIP
export const IS_TABOOLA = !!window.APP_SETTINGS?.feature_flags?.taboola
export const IS_FEATURE_enableComments = !!window.APP_SETTINGS?.feature_flags?.enableComments
export const IS_EXIT_SCREEN_ENABLE = !!window.APP_SETTINGS?.feature_flags?.exitScreen;
export const IS_ARTICLE_SWIPER_ENABLE = !!window.APP_SETTINGS?.feature_flags?.articleSwiper;
export const IS_FEATURE_enableScrollWrapper = !!window.APP_SETTINGS?.feature_flags?.enableScrollWrapper;
export const IS_FEATURE_PAGINATOR_LIST = !!window.APP_SETTINGS?.feature_flags?.paginatorList;
export const IS_FEATURE_ARTICLE_PRELOAD = window.APP_SETTINGS?.feature_flags?.articlePreload > 0;
export const IS_SENTRY_ENABLED = !!window.APP_SETTINGS?.feature_flags?.enableSentry;
export const TIMEOUT_ADS_LOAD = window.APP_SETTINGS?.feature_flags?.timeoutAds ?? 0;
export const IS_COMMERCIALBREAK_DISABLE_TIMEOUT = !!window.APP_SETTINGS?.feature_flags?.commercialBreakRedirectTimeout;
export const IS_TABOOLA_NEWSROOM = !!window.APP_SETTINGS?.feature_flags?.taboolaNewsroom;
export const IS_NEXT_ARTICLE_POPUP = !!window.APP_SETTINGS?.feature_flags?.nextArticlePopup;
export const IS_ARTICLE_PV_FROM_RELATED_ARTICLE = !!window.APP_SETTINGS?.feature_flags?.articlePVFromRelatedArticle;
export const IS_OPEN_ARTICLE_THROUGH_MAIN_PAGE = !!window.APP_SETTINGS?.feature_flags?.openArticleThroughMainPage;
export const IS_FEATURE_CTA_SUPPORT_BUTTON = !!window.APP_SETTINGS?.feature_flags?.ctaSupportButton;
export const IS_NETFLIX_AXELF_ACTION = !!window.APP_SETTINGS?.feature_flags?.netflixAxelFAction;
export const IS_LOGO_NAV_SPECIAL = !!window.APP_SETTINGS?.feature_flags?.logo_nav_special;
export const IS_SHOW_STATIC_BLOCKS = !!window.APP_SETTINGS?.feature_flags?.staticBlockToShow;


export const HIDE_ARTICLE_BOTTOM_AUTHOR = !!window.APP_SETTINGS?.feature_flags?.hideArticleBottomAuthor
export const HIDE_ARTICLE_SEE_ALSO_SECTION = !!window.APP_SETTINGS?.feature_flags?.hideArticleSeeAlsoSection
export const HIDE_ARTICLE_ART_BILLBOARD_3 = !!window.APP_SETTINGS?.feature_flags?.hideArticleARTBillboard3

export const AD_PUBLISHER = window.APP_SETTINGS?.feature_flags?.adPublisher;

/** feature flags redesign */

export const DESKTOP_BREAKPOINT = 996;

// feature flags redesign bez premium
export const DISABLE_PREMIUM_FLAG_ACTIVE = IS_PRIVILEGED_USER || !!window.APP_SETTINGS?.feature_flags?.disablePremium

/** feature flags extended value */
export const SHOW_STATIC_BLOCKS_CONFIG = new Proxy(IS_SHOW_STATIC_BLOCKS ? ({
    default: 4,
    category_render_blocks: {},
    ...window.APP_SETTINGS.feature_flags_extended?.['staticBlockToShow']?.extended_value ?? {}
}) : ({
    default: 0,
    category_render_blocks: {},
}), {
    get(settings, property) {
        switch (property) {
            case 'category_render_blocks':
                return new Proxy(settings[property], {
                    get(categoryRenderBlocks, categoryId) { return categoryRenderBlocks[categoryId] ?? settings.default }
                })
            default:
                return settings[property];
        }
    }
});

export { APP_REFRESH_TIME as RELOAD_TIME }